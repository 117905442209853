.bio {
  padding-bottom: 30px;
  margin-bottom: -30px;
  @include screen-md(){
    padding-bottom: 40px;
    margin-bottom: -40px;
    background: url(../images/bio.png) bottom center no-repeat;
    background-size: contain;
  }
  @include screen-xl(){
    padding-bottom: 60px;
    margin-bottom: -60px;
  }

  .carousel-caption {
    position: static;
    color: black;
    text-align: left;
  }

  .bio-item {
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 36px;
  }

  .bio-date {
    padding-bottom: 24px;
    font-weight: bold;
  }

  .bio-desc {

  }

  #bioCarousel {
    display: flex;
    flex-direction: column;
  }

  ol.carousel-indicators {
    order: 1;
    position: static;
    margin: 0;
    justify-content: left;
    font-weight: bold;
    li {
      border-top: 5px solid $cBlue;
      height: auto;
      width: 36px;
      text-indent: 0;
      margin-left: 0;
      margin-right: 8px;
    }
    li.active {
      border-top-color: $cRed;
    }
  }
  @include screen-md {
    .carousel-item{
      min-height: 570px;
    }
    ol.carousel-indicators li {
      border-top-width: 10px;
      width: 89px;
      margin-right: 18px;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      padding-top: 20px;
    }
  }
}