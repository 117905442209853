header{
  background: #010102;
  position: relative;
  //background: url("../images/bg_header.png") no-repeat;
  //background-size: cover;
  //@include screen-md {
    background: url("../images/bg_header.png") no-repeat;
    background-size: 100% 100%;
  //}
}
.navbar{
  padding-top: 40px;
  position: relative;
  z-index: 2;
  @include screen-sm{
    padding: 0;
  }
  .navbar-nav{
    flex-wrap: wrap;
  }
  .nav-item a{
    color: white;
    font-size: 20px;
    padding: 0 16px 0 8px !important;
    @include screen-lg{
      font-size: 16px;
    }
    &:hover{
      color: $cRed;
    }
  }
  .nav-item.active a{
    color: $cRed;
  }
  .navbar-toggler-icon{
    background: url("../images/gamburder_menu_ico.png") center no-repeat;
  }
  @include screen-sm{
    .navbar-collapse{
      padding-bottom: 60px;
    }
  }
}
#mainCarousel{
  margin-right: 0;
  margin-left: 0;
  position: relative;
  margin-top: -40px;
  @include screen-md{
    margin-top: -106px;
  }
  @include screen-lg{
    margin-top: -96px;
  }
  @include screen-sm{
    img.d-block.w-100{
      height: 580px;
      width: 100%;
    }
  }
  .main-carousel-item{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    right: 0;
    left: 10px;
    width: 100%;
    height: 580px;
    padding-top: 80px;
    &>*{
      width: 300px;
    }
    @include screen-md{
      justify-content: center;
      align-items: left;
      padding-top: 0;
      height: 100%;
      width: 380px;
      left: 24px;
      &>*{
        width: auto;
      }
    }
    @include screen-lg{
      width: 550px;
    }
    .main-carousel-item_surname{
      font-weight: 700;
      text-transform: uppercase;
      font-size: 54px;
      line-height: 60px;
    }
    .main-carousel-item_name{
      font-weight: 300;
      font-size: 34px;
      line-height: 38px;
      letter-spacing: -2px;
    }
    .main-carousel-item_desc{
      color: $cRed;
      text-transform: uppercase;
      padding-bottom: 30px;
      font-weight: bold;
      padding-top: 10px;
      @include screen-xl{
        padding-bottom: 70px;
      }
    }
    .main-carousel-item_blockquote{
      font-weight: 400;
      padding-bottom: 205px;
    }
    @include screen-md{
      .main-carousel-item_surname{
        font-size: 75px;
        line-height: 80px;
      }
      .main-carousel-item_name{
        font-size: 47px;
        line-height: 50px;
        letter-spacing: -2px;
      }
      .main-carousel-item_desc{
        font-size: 18px;
        line-height: 28px;
      }
      .main-carousel-item_blockquote{
        font-size: 24px;
        line-height: 25.62px;
        padding-bottom: 0;
      }
    }
    @include screen-lg{
      .main-carousel-item_surname{
        font-size: 105px;
        line-height: 105px;
      }
      .main-carousel-item_name{
        font-size: 66px;
        line-height: 80px;
        letter-spacing: -2.5px;
      }
      .main-carousel-item_desc{
        font-size: 20px;
        line-height: 33.8px;
      }
      .main-carousel-item_blockquote{
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
  .carousel-control-next,
  .carousel-control-prev{
    width: 70px;
    height: 70px;
    right: 0;
    left: auto;
    background: $cBlue;
    @include screen-sm{
      display: none;
    }
  }
  .carousel-control-next{
    top: 30%;
  }
  .carousel-control-prev{
    left: auto;
    top: calc(30% + 70px);
  }
  & + .carousel-other-buttons{
    position: absolute;
    bottom: 40px;
    left: 0;
    text-align: center;
    width: 100%;
    .btn{
      width: 250px;
      display: block;
      margin: 0 auto;
      @include screen-md {
        display: inline-block;
        width: auto;
      }
    }
    .btn + .btn{
      margin-top: 25px;
      margin-left: auto;
    }
    @include screen-md {
      width: auto;
      bottom: 70px;
      left: 40px;
      .btn + .btn{
        margin-left: 25px;
        margin-top: 0;
      }
    }
  }
  @include screen-sm{
    margin-right: -15px;
    margin-left: -15px;
  }
  @include screen-md{
    margin-right: calc( (-1) * (100vw - 700px) / 2);
    margin-left: calc( (-1) * (100vw - 700px) / 2);
    .main-carousel-item{
      left: calc(24px + (100vw - 700px) / 2);
    }
  }
  @include screen-lg{
    margin-right: calc( (-1) * (100vw - 930px) / 2);
    margin-left: calc( (-1) * (100vw - 930px) / 2);
    .main-carousel-item{
      left: calc(24px + (100vw - 930px) / 2);
    }
  }
  @include screen-xl{
    margin-right: calc( (-1) * (100vw - 1130px) / 2);
    margin-left: calc( (-1) * (100vw - 1130px) / 2);
    .main-carousel-item{
      left: calc(24px + (100vw - 1130px) / 2);
    }
  }
}

.v-card{
  color: white;
  padding-top: 15px;
  @include screen-md{
    width: 60%;
    float: left;
  }
  &>*{
    text-align: left;
    width: 300px;
    margin: 0 auto;
    @include screen-md{
      margin-left: 24px;
      width: auto;
    }
  }
  &-item_surname{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 54px;
    line-height: 60px;
  }
  &-item_name{
    font-weight: 300;
    font-size: 34px;
    line-height: 38px;
    letter-spacing: -2px;
  }
  &-item_desc{
    color: $cRed;
    text-transform: uppercase;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  @include screen-md{
    &-item_surname{
      font-size: 75px;
      line-height: 80px;
    }
    &-item_name{
      font-size: 47px;
      line-height: 50px;
      letter-spacing: -2px;
    }
    &-item_desc{
      font-size: 18px;
      line-height: 28px;
    }
  }
  @include screen-lg{
    &-item_surname{
      font-size: 105px;
      line-height: 120px;
    }
    &-item_name{
      font-size: 66px;
      line-height: 70px;
      letter-spacing: -2.5px;
    }
    &-item_desc{
      font-size: 20px;
      line-height: 33.8px;
    }
  }
  & + .carousel-other-buttons{
    text-align: center;
    width: 100%;
    padding-bottom: 30px;
    @include screen-md{
      width: 40%;
      float: right;
    }
    @include screen-lg{
      padding-top: 110px;
    }
    .btn{
      width: 250px;
      display: block;
      margin: 0 auto;
      @include screen-md {
        margin-right: 0;
      }
    }
    .btn + .btn{
      margin-top: 25px;
      margin-left: auto;
    }
    /*@include screen-md {
      width: auto;
      bottom: 70px;
      left: 40px;
      .btn + .btn{
        margin-left: 25px;
        margin-top: 0;
      }
    }*/
  }
}
footer{
  background: #000;
  color:white;
  padding-top: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  ul.social{
    list-style: none;
    margin: 0;
    display: flex;
    padding: 0 0 30px;
    li+li{
      margin-left: 25px;
    }
  }
  @include screen-md {
    padding-top: 30px;
    padding-bottom: 15px;
    ul.social{
      padding-top: 5px;
      justify-content: flex-end;
    }
  }
}
