body{
  font-size: 16px;
}
.btn {
  border-radius: 0;
  border: none;
  font-size: 20px;
  padding: 22px 26px;
}

.container {
  position: relative;
  font-size: 16px;
}

a{
  color: $cRed;
  &:hover{
    color: $cBlue;
  }
}

.btn-danger:not(:disabled):not(.disabled) {
  background: $cRed;
  &:hover {
    background: darken($cRed, 8%);
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    background: $cRed;
    box-shadow: none;
  }
  &:active:focus {
    background: darken($cRed, 15%);
    box-shadow: none;
  }
}

.btn-primary:not(:disabled):not(.disabled) {
  background: $cBlue;
  &:hover {
    background: darken($cBlue, 8%);
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    background: $cBlue;
    box-shadow: none;
  }
  &:active:focus {
    background: darken($cBlue, 15%);
    box-shadow: none;
  }
}

.form-control{
  border-radius: 0;
  font-size: 20px;
}

main {
  padding-bottom: 30px;
  @include screen-md {
    padding-bottom: 40px;
  }
  @include screen-xl {
    padding-bottom: 60px;
  }
}

h1 {
  padding-top: 30px; //60
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  padding-bottom: 30px; //60
  margin: 0;
  @include screen-md {
    font-size: 34px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include screen-xl {
    font-size: 48px;
    line-height: 48px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.center {
  text-align: center;
}

.center .btn {
  width: 280px;
}

.filter {
  padding-bottom: 10px;
  @include screen-md {
    padding-bottom: 15px;
  }
  @include screen-lg {
    padding-bottom: 25px;
  }
  @include screen-xl {
    padding-bottom: 30px;
  }
}