.events {
  .event {
    border: 2px solid #e9e8e8;
    padding: 35px 18px 124px 18px;
    position: relative;
    margin-bottom: 30px;
    @include screen-lg(){
      padding: 60px 200px 60px 45px;
    }
  }
  .event-colaps-btn {
    background: url("../images/open_icon.png");
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    bottom: 40px;
    transition: all 0.4s ;
    @include screen-lg(){
      left: auto;
      right: 25px;
      margin-left: 0;
      bottom: 0;
      top: 80px;
    }
  }
  .show + .event-colaps-btn {
    background: url("../images/close_icon.png");
  }
  .event-time {
    font-size: 20px;
    font-weight: 300;
    line-height: 18px;
    padding-bottom: 25px;
  }
  .event-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    padding-bottom: 20px;
  }
  .event-description {
    //display: none;
  }
}