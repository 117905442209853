@mixin font-face($name,$file,$weight,$style){
  @font-face {
    font-family: $name;
    src: url("../fonts/#{$file}.eot");
    /*src: url('../fonts/#{$file}.woff2') format('woff2'),
    url('../fonts/#{$file}.woff') format('woff'),
    url('../fonts/#{$file}.ttf') format('truetype'),
    url('../fonts/#{$file}.svg##{$file}') format('svg'),
    url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype');*/
    src: url('../fonts/#{$file}.eot?#iefix') format('embedded-opentype')
    url('../fonts/#{$file}.woff') format('woff'),
    url('../fonts/#{$file}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face('Segoe UI','SegoeUIRegular', normal, normal);
@include font-face('Segoe UI','SegoeUILight', 100, normal);
@include font-face('Segoe UI','SegoeUISemiBold', bold, normal);
@include font-face('Segoe UI','SegoeUIItalic', normal, italic);

/*@include font-face('Helvetica','Helvetica', normal, normal);
@include font-face('Helvetica','HelveticaNeue', bold, normal);
@include font-face('HelveticaNeueDeskInterface','HelveticaNeueDeskInterface', normal, normal);
@include font-face('Plumb','PlumbBoldC', bold, normal);
@include font-face('Plumb','PlumbLightC', 300, normal);
@include font-face('Plumb','PlumbMediumC', 500, normal);*/