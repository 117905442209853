.comments{
  .comments-header{
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    padding: 30px 0 20px;
    @include screen-md {
      font-size: 34px;
      padding: 40px 0 25px;
    }
    @include screen-xl {
      padding: 50px 0 30px;
      font-size: 48px;
      line-height: 48px;
    }
  }
  .comments-social{
    padding: 20px 0;
    @include screen-md {
      padding: 25px 0 25px;
    }
    @include screen-xl {
      padding: 30px 0 30px;
    }
  }
  .comments-item{
    padding: 20px 0;
    @include screen-md {
      padding: 25px 0 25px;
    }
    @include screen-xl {
      padding: 30px 0 30px;
    }
    .comments-meta{
      .comments-avatar{
        float: left;
        margin-right: 30px;
        margin-bottom: 30px;
      }
    }
    .comments-text{
      clear: both;
    }
  }

}