.news{
  &-item{
    padding-bottom: 35px;
    &_image{
      margin-bottom: 20px;
      @include screen-md{
        margin-bottom: 25px;
      }
      @include screen-lg{
        margin-bottom: 35px;
      }
      @include screen-xl{
        margin-bottom: 45px;
      }
    }
    .description{
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: #000;
      @include screen-md{
        font-size: 22px;
      }
      @include screen-lg{
        margin-bottom: 24px;
      }
    }
  }
  @include screen-md{
    &-item-main{
      background: $cGray;
      time{
        padding-left: 35px;
        padding-right: 35px;
      }
      .description{
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 40px;
      }
    }
  }
  &-detail{
    &_image{
      margin-bottom: 20px;
      display: block;
      width: 100%;
      @include screen-md{
        margin-bottom: 25px;
      }
      @include screen-lg{
        margin-bottom: 35px;
      }
      @include screen-xl{
        margin-bottom: 45px;
      }
    }
    .news-text{
      font-size: 16px;
      padding-bottom: 30px;
      @include screen-md{
        font-size: 18px;
        padding-bottom: 40px;
      }
      @include screen-lg{
        font-size: 20px;
        padding-bottom: 50px;
      }
    }
  }
  time{
    font-size: 20px;
    font-weight: 300;
    line-height: 18px;
    margin-bottom: 20px;
    @include screen-md{
      margin-bottom: 25px;
    }
    @include screen-lg{
      margin-bottom: 35px;
    }
    @include screen-xl{
      margin-bottom: 45px;
    }
    display: block;
  }

}